import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
ReactGA.initialize("UA-169923260-01");

const suppressedWarnings = ['validateDOMNesting'];

const originalError = console.error;
console.error = function (message, ...args) {
  if (suppressedWarnings.some((warning) => message.includes(warning))) {
    return;
  }
  originalError.call(console, message, ...args);
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
