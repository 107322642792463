import React from "react";
import Home from "./Home";
import Contact from "./Contact";
import ContactConfirm from "./ContactConfirm";
import About from "./About";
import Resume from "./Resume";
import cloud from "./assets/Wordcloud.png";
import NavBar from "./NavBar";
import MyWork from "./MyWork";
import Intro from "./Intro";
import { Route } from "react-router-dom";
import "./App.css";
import $ from "jquery";

const Waypoint = window.Waypoint;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    // jQuery Waypoint animation logic
    $(".animate").each(function () {
      const object = $(this);
      const delay = object.attr("data-delay") || "0";

      new Waypoint({
        element: object[0],  // Ensure you're passing the correct element
        handler: function (direction) {
          setTimeout(() => {
            object.addClass("animated");
          }, delay);
          this.destroy();  // Safely destroy waypoint after it triggers
        },
        offset: "90%",  // Set the desired offset
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    Waypoint.destroyAll(); // Cleanup all Waypoints to prevent memory leaks
  }


  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div>
        <div className="portfolio-header">
          <NavBar />
        </div>
        <div id="Home">
          <Route path="/" exact component={Home} />
        </div>
        <Route path="/Intro" exact component={Intro} />
        <Route path="/About" component={About} />
        <Route path="/MyWork" component={MyWork} />
        <Route path="/Contact" component={Contact} />
        <Route path="/ContactConfirm" component={ContactConfirm} />
        <Route path="/Resume" component={Resume} />
        <div className="bottom-container">
          <img alt="Skills wordcloud" className="bottom-wordcloud" src={cloud} />
        </div>
      </div>
    );
  }
}

export default App;
