import React from "react";

class ContactConfirm extends React.Component {
  render() {
    return (
      <div className="contact-form-container">
      </div>
    );
  }
}

export default ContactConfirm;
