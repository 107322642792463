import React from "react";

class Resume extends React.Component {
  render() {
    return (
      <div className="resume-page">
        <div id="Resume"></div>
        <br></br>
        <div className="full-resume-container animate fade-in-from-bottom">
          <iframe
            className="full-resume-content"
            title="Scott Kutler Resume"
            src="https://docs.google.com/document/d/e/2PACX-1vTE8R3-TxsBTkFFZWSgknXhbybqLFWuE4fs-Iqi_MGr2FOvKosnC1EOIM8cXLwgdo6lu283zP5MdzJA/pub?embedded=true"
          ></iframe>
        </div>
      </div>
    );
  }
}
export default Resume;
