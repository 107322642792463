import React from "react";
import SocialButtons from "./SocialButtons";

class Contact extends React.Component {
  componentDidMount() { }

  render() {
    return (
      <div className="contact-form-container">
        <div className="contact-form">
          <address className="contact-myinfo">
            <a href="mailto:scott.kutler@gmail.com">scott.kutler@gmail.com</a> |{" "}
            <a href="tel:469-387-0895">469.387.0895</a> |{" "}
            <a
              href="https://maps.google.com/?q=Bethesda,%20Maryland"
              rel="noopener noreferrer"
              target="_blank"
            >
              Bethesda, MD, USA
            </a>
          </address>
          <SocialButtons />
        </div>

        <div className="SmallContact">
          <address className="contact-myinfo">
            <a href="mailto:scott.kutler@gmail.com">scott.kutler@gmail.com</a>
            <br />
            <a href="tel:469-387-0895">469.387.0895</a>
            <br />
            <a
              href="https://maps.google.com/?q=Bethesda,%20Maryland"
              rel="noopener noreferrer"
              target="_blank"
            >
              Bethesda, MD, USA
            </a>
          </address>
          <SocialButtons />
        </div>
      </div>
    );
  }
}

export default Contact;
