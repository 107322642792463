import React from "react";
import Contact from "./Contact";
import About from "./About";
import Intro from "./Intro";
import MyWork from "./MyWork";
import "./App.css";

class Home extends React.Component {
  render() {
    return (
      <div>
        <Intro />
        <section>
          <h2 className="aboutme-header animate fade-in-from-bottom">About Me</h2>
          <About />
        </section>
        <section>
          <h2 className="mywork-header animate fade-in-from-bottom">Work Portfolio</h2>
          <MyWork />
        </section>
        <section>
          <h2 className="contact-header animate fade-in-from-bottom">Contact Me</h2>
          <Contact />
        </section>
      </div>
    );
  }
}

export default Home;
