import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const StyledAccordion = withStyles({
  root: {
    border: "5px solid black ",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: "5px solid black",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  expanded: {},
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgb(62, 126, 179)",
    borderBottom: "3px solid black",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "0px ",
    },
  },
  expanded: {},
})(AccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "#325b84",
    borderBottom: "3px solid black",
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

export default function WorkPanel() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <div className="workcontainer-component animate fade-in-from-bottom">
      <StyledAccordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        TransitionProps={{
          timeout: 100,
        }}
      >
        <StyledAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>
            <p className="c4">
              <span
                className={`c20 ${expanded === "panel1" ? "carot-rotated" : ""}`}
              ></span>
              <span className="c9">Senior Developer | Blueprint Interactive</span>
              <span alt="keyboard" className="c21">🌎</span>
            </p>
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            <p className="c4">
              <span className="c7">July 2020 &ndash; Present</span>
            </p>
            <p className="c4 c10">
              <span className="c13"></span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Responsible for developing and maintaining dozens of websites ranging from static landing pages to full PHP-based CMS sites
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Work closely with designers, account leads, and developers to launch sites for both internal use and clients
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Utilize HTML, CSS, JavaScript, PHP, React, and WordPress in full-stack web development; including implementation of several APIs such as Stripe, HubSpot, and ActionNetwork
              </span>
            </p>
            <p className="c4 c10">
              <span className="c3"></span>
            </p>
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>

      <div className="expansion-spacing"></div>

      <StyledAccordion
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        TransitionProps={{
          timeout: 100,
        }}
      >
        <StyledAccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>
            <p className="c4">
              <span
                className={`c20 ${expanded === "panel2" ? "carot-rotated" : ""}`}
              ></span>
              <span className="c9">Founder & Software Engineer | Brotech LLC</span>
              <span alt="phone" className="c21">📱</span>
            </p>
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            <p className="c4">
              <span className="c7">October 2015 &ndash; Present</span>
            </p>
            <p className="c4 c10">
              <span className="c13"></span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Created and maintain operation of one of the top LGBT+ social networks worldwide with over 1,000,000 downloads and a 4.7/5 App Store Rating
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Coordinated and validated the development of applications on iOS, Android, and a supporting administrative web interface throughout multiple versions
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Defined requirements and oversaw the operation of teams participating in 50+ Kanban and Scrum life cycles via JIRA, Github, and Trello
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Facilitated 100+ daily meetings with the team’s Scrum Master, Developers, Designers, and QA Team
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Performed market research (ie. conducting focus groups, managing social media, and competitive analysis) to generate quarterly metrics reports and leverage findings to prioritize product development efforts
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Provided traditional help desk support and customer outreach via social media engagement to 100,000+ followers
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Managed development and deployment life-cycles using TestFlight, Google Play Dev. Console, Bash, VS Code, and other tools.
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Monitored and managed production systems in a cloud-based deployment environment (AWS: Lambda, S3, EC2, RDS)
              </span>
              <span className="c11">&nbsp;</span>
              <span className="c3">&nbsp;</span>
            </p>
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>

      <div className="expansion-spacing"></div>

      <StyledAccordion
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        TransitionProps={{
          timeout: 100,
        }}
      >
        <StyledAccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography>
            <p className="c4">
              <span
                className={`c20 ${expanded === "panel3" ? "carot-rotated" : ""}`}
              ></span>
              <span className="c9">
                Software Engineering Fellow | General Assembly
              </span>
              <span className="c22">📚</span>
            </p>
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            <p className="c4">
              <span className="c7">February 2020 &ndash; April 2020</span>
            </p>
            <p className="c4">
              <span className="c13">&nbsp;</span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp;Graduated top of the class in a Full-Stack Software Engineering course with 500+ hours of Computer Science work focused
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; This full-time immersive course focused in-depth on multiple coding languages and frameworks including: HTML, CSS, Javascript, React.js, Node.js, Django, Python, MongoDB, and Express
              </span>
            </p>
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>

      <div className="expansion-spacing"></div>

      <StyledAccordion
        square
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        TransitionProps={{
          timeout: 100,
        }}
      >
        <StyledAccordionSummary
          aria-controls="panel4d-content"
          id="panel3d-header"
        >
          <Typography>
            <p className="c4">
              <span
                className={`c20 ${expanded === "panel4" ? "carot-rotated" : ""}`}
              ></span>
              <span className="c9">
                General Manager & Web Developer | Tenley Grill{" "}
              </span>
              <span className="c27">
                💻
              </span>
            </p>
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            <p className="c4">
              <span className="c7">​​February 2015 &ndash; February 2020 </span>
            </p>
            <p className="c5">
              <span className="c13"></span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Created and maintained restaurant homepage and a responsive web app for customers
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Interviewed, hired, and trained over 100 employees on operations, menu, service standards, and company policy
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp;Utilized internal pricing strategies and operational oversight to control costs via staff management, scheduling, and inventory control to reach quarterly sales targets.
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp;Coordinated and hosted large group events for 350+ patrons including fundraisers, parties, and live performances
              </span>
            </p>
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>

      <div className="expansion-spacing"></div>

      <StyledAccordion
        square
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        TransitionProps={{
          timeout: 100,
        }}
      >
        <StyledAccordionSummary
          aria-controls="panel5d-content"
          id="panel5d-header"
        >
          <Typography>
            <p className="c4">
              <span
                className={`c20 ${expanded === "panel5" ? "carot-rotated" : ""}`}
              ></span>
              <span className="c9">Certified Apple Technician | Apple Inc. </span>
              <span className="c21">
                👨‍💻
              </span>
            </p>
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            <p className="c4">
              <span className="c7">August 2008 &ndash; November 2014 </span>
            </p>
            <p className="c5">
              <span className="c13"></span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Provided training, service, and hands-on support for Apple customers&rsquo; hardware and software
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp; Developed weekly training and mentorship plans to support employee growth
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp;Maintained knowledge of Apple&rsquo;s products, software, and services to provide guidance and support to customers{" "}
              </span>
            </p>
            <p className="c6">
              <span className="c1">
                &bull; &nbsp;Recognized for having one of the highest customer satisfaction ratings (NPS) among all technicians in the market
              </span>
            </p>
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
}
