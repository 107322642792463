import React from "react";
import { SocialIcon } from "react-social-icons";

const SocialButtons = () => {
  return (
    <div className="Social-container">
      <div className="Social-box animate fade-in-from-bottom">
        <SocialIcon
          url="https://www.linkedin.com/in/scottkutler/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Scott Kutler LinkedIn"
        />
      </div>

      <div className="Social-box animate fade-in-from-bottom" >
        <SocialIcon
          url="https://www.facebook.com/scottkutler"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Scott Kutler Facebook"
        />
      </div>

      <div className="Social-box animate fade-in-from-bottom">
        <SocialIcon
          url="https://www.github.com/skut21x-ga"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Scott Kutler GitHub"
        />
      </div>
    </div>
  );
};

export default SocialButtons;
